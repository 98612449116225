import Table from "components/common/table/Table";
import React, { FC } from "react";
import "./InformationContent.style.css";
import { IInformationContentT } from "./InformationContent.types";

const InformationContent: FC<IInformationContentT> = ({ loader, data }) => {
  return (
    <>
      <h5 className="text-primary mb-2">
        <p className="primary">Active Students</p>
        <Table isActive={true} loader={loader} data={data} />
        <p className="primary">Archive Students</p>
        <Table isActive={false} loader={loader} data={data} />
      </h5>
    </>
  );
};

export default InformationContent;
