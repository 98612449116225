import React from "react";
import makeQueryString from "services/make_query";

export const SearchParams = (): [(object: object) => void, () => any] => {
  const setParams = (object: object) => {
    const params = makeQueryString(object);
    // console.log(params, 'params')
    window.history.replaceState(
      null,
      "Unical Erp",
      window.location.href.split("?")[0] + params
    );
  };

  const getParams = () => {
    const checkType = (value: any) => {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    };
    let object = new URLSearchParams(document.location.search);
    let params = Object.fromEntries(object.entries());
    Object.keys(params).map((obj: any) => {
      if (obj !== "search") params[obj] = checkType(params[obj]);
    });
    return params;
  };

  return [setParams, getParams];
};
