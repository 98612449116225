export const Month = [
  {
    value: 1,
    // name: "JANUARY",
    name: "Yanvar",
  },
  {
    value: 2,
    // name: "FEBRUARaY",
    name: "Fevral",
  },
  {
    value: 3,
    // name: "MARCH",
    name: "Mart",
  },
  {
    value: 4,
    // name: "APRIL",
    name: "Aprel",
  },
  {
    value: 5,
    // name: "MAY",
    name: "May",
  },
  {
    value: 6,
    // name: "JUNE",
    name: "Iyun",
  },
  {
    value: 7,
    // name: "JULY",
    name: "Iyul",
  },
  {
    value: 8,
    // name: "AUGUST",
    name: "Avgust",
  },
  {
    value: 9,
    // name: "SEPTEMBER",
    name: "Sentabr",
  },
  {
    value: 10,
    // name: "OCTOBER",
    name: "Oktabr",
  },
  {
    value: 11,
    // name: "NOVEMBER",
    name: "Noyabr",
  },
  {
    value: 12,
    // name: "DECEMBER",
    name: "Dekabr",
  },
];
