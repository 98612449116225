import { Month } from "assets/Arrays";
import CustomSelect from "components/common/customSelect/CustomSelect";
import get from "lodash.get";
import { FC } from "react";
import "./informationHeader.style.css";
import { IInformationHeaderT } from "./InformationHeader.types";

const InformationHeader: FC<IInformationHeaderT> = ({ data }) => {
  const monthFind = () => {
    return Month?.find((month) => month?.value === data?.month)?.name;
  };

  return (
    <>
      <div className="header">
        <div className="title">Guruh nomi: {get(data, "group.name", "")}</div>
        <div className="titleright">
          <div>Yil: {get(data, "year", "")}</div>
          <div>Oy: {monthFind()}</div>
        </div>
        <div className="selects">
          <div className="select-item">
            <CustomSelect label="Year" value={get(data, "year", "")} />
          </div>
          <div className="select-item">
            <CustomSelect label="Month" value={monthFind()} />
          </div>
        </div>
      </div>
    </>
  );
};

export default InformationHeader;
