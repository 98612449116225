import DoneIcon from "./assets/DoneIcon";
import ExclamationIcon from "./assets/ExclamationIcon";
import MinusIcon from "./assets/MinusIcon";
import { IAttendanceT } from "./Attendance.types";
import "./Attendance.style.css";
//@ts-ignore
import attendanceDifferenceIcon from "../../../assets/attendancedifferenceIcon.png";

const AttendanceCircle = ({ attendance, checkAttendance }: IAttendanceT) => {
  return (
    <>
      <div className="attendance">
        <div
          data-title={attendance?.reason}
          className={`circle ${
            checkAttendance
              ? "blue-add"
              : attendance?.moderatorState && !attendance?.state
              ? attendance?.isTrial
                ? "circle-yellow"
                : attendance?.moderatorState === "attended"
                ? "circle-green"
                : attendance?.moderatorState === "reasonable"
                ? "circle-darkgray"
                : attendance?.moderatorState === "unreasonable"
                ? "circle-red"
                : attendance?.moderatorState === "frozen"
                ? "circle-blue"
                : checkAttendance
                ? "blue-add"
                : "default-color"
              : attendance?.state && !attendance.moderatorState
              ? attendance?.isTrial
                ? "circle-yellow"
                : attendance?.state === "attended"
                ? "circle-green"
                : attendance?.state === "reasonable"
                ? "circle-darkgray"
                : attendance?.state === "unreasonable"
                ? "circle-red"
                : attendance?.state === "frozen"
                ? "circle-blue"
                : checkAttendance
                ? "blue-add"
                : "default-color"
              : attendance?.state &&
                attendance?.moderatorState &&
                attendance?.state !== attendance.moderatorState
              ? "not-equal"
              : attendance?.isTrial
              ? "circle-yellow"
              : attendance?.moderatorState === "attended"
              ? "circle-green"
              : attendance?.moderatorState === "reasonable"
              ? "circle-darkgray"
              : attendance?.moderatorState === "unreasonable"
              ? "circle-red"
              : attendance?.moderatorState === "frozen"
              ? "circle-blue"
              : checkAttendance
              ? "blue-add"
              : "default-color"
          }`}
        >
          <>
            {!attendance ? (
              ""
            ) : attendance?.moderatorState && !attendance?.state ? (
              attendance?.isTrial ? (
                <ExclamationIcon />
              ) : attendance?.moderatorState === "attended" ? (
                <DoneIcon />
              ) : attendance?.moderatorState === "reasonable" ? (
                <ExclamationIcon />
              ) : attendance?.moderatorState === "unreasonable" ? (
                <MinusIcon />
              ) : (
                ""
              )
            ) : !attendance?.moderatorState && attendance?.state ? (
              attendance?.isTrial ? (
                <ExclamationIcon />
              ) : attendance?.state === "attended" ? (
                <DoneIcon />
              ) : attendance?.state === "reasonable" ? (
                <ExclamationIcon />
              ) : attendance?.state === "unreasonable" ? (
                <MinusIcon />
              ) : (
                ""
              )
            ) : attendance?.moderatorState && attendance?.state ? (
              <>
                {attendance?.moderatorState !== attendance?.state ? (
                  <>
                    <img
                      src={attendanceDifferenceIcon}
                      width={20}
                      height={20}
                      alt=""
                    />
                  </>
                ) : attendance?.isTrial ? (
                  <ExclamationIcon />
                ) : attendance?.state === "attended" ? (
                  <DoneIcon />
                ) : attendance?.state === "reasonable" ? (
                  <ExclamationIcon />
                ) : attendance?.state === "unreasonable" ? (
                  <MinusIcon />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default AttendanceCircle;
