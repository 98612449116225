import React, { Fragment } from "react";
import get from "lodash.get";
import { IAttendance, ILesson } from "types/common";
import AttendanceCircle from "../attendanceCircle/AttendanceCircle";
import Loader from "../loader/Loader";
import "./Table.style.css";
import { ITableT } from "./Table.types";

const Table = ({ isActive, loader, data }: ITableT) => {
  return (
    <>
      <div className="table-styles">
        <div className="scroll-table">
          <table className="table tables">
            <thead className="" style={{ position: "initial" }}>
              <tr>
                <td>№</td>
                <td>Name</td>
                <td>Phone number</td>
                {get(data, "lessons", [])?.map((item: ILesson) => {
                  return (
                    <td>
                      <div>
                        <p id={item._id} className="course-date-style">
                          {item?.date?.slice(8, 10) +
                            "." +
                            item?.date?.slice(5, 7)}
                        </p>
                      </div>
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {isActive ? (
                <Fragment>
                  {get(data, "attendances", [])?.map(
                    (item: IAttendance, index: number) => {
                      return item?.state === "active" ||
                        item?.state === "frozen" ? (
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>
                            <div
                              className="d-flex align-items-center"
                              style={{ display: "flex" }}
                            >
                              <div>
                                {get(item, "student.firstName", "") +
                                  " " +
                                  get(item, "student.lastName", "")}
                              </div>
                              {get(item, "state", "") === "frozen" ? (
                                <div className="state-button">Frozen</div>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td>{get(item, "student.phoneNumber", "")}</td>
                          {get(data, "lessons", [])?.map((lesson: ILesson) => {
                            let t = get(item, "attendances", [])?.find(
                              (attended: IAttendance) =>
                                attended?.lessonId === lesson?._id
                            );
                            let joinedDate = new Date(item.joinedAt)?.getTime();
                            let lessonDate = new Date(lesson.date)?.getTime();
                            let x =
                              lessonDate < joinedDate - 24 * 60 * 60 * 1000;
                            return (
                              <td key={lesson?._id}>
                                {
                                  <AttendanceCircle
                                    attendance={t}
                                    checkAttendance={x}
                                  />
                                }
                              </td>
                            );
                          })}
                        </tr>
                      ) : (
                        ""
                      );
                    }
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {get(data, "leftStudendsAttendances", [])?.map(
                    (item: IAttendance, index: number) => {
                      return (
                        <tr key={item?._id}>
                          <td>{index + 1}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              {get(item, "student.firstName", "") +
                                " " +
                                get(item, "student.lastName", "")}
                              {get(item, "state", "") === "frozen" ? (
                                <div className="state-button">Frozen</div>
                              ) : (
                                ""
                              )}
                            </div>
                          </td>
                          <td>{get(item, "student.phoneNumber", "")}</td>
                          {get(data, "lessons", [])?.map((lesson: ILesson) => {
                            let t = item?.attendances?.find(
                              (attended: IAttendance) =>
                                get(attended, "day", "") === lesson.date
                            );
                            let joinedDate = new Date(item.joinedAt)?.getTime();
                            let lessonDate = new Date(lesson.date)?.getTime();
                            let x =
                              lessonDate < joinedDate - 24 * 60 * 60 * 1000;
                            return (
                              <td key={lesson?._id}>
                                {
                                  <AttendanceCircle
                                    attendance={t}
                                    checkAttendance={x}
                                  />
                                }
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }
                  )}
                </Fragment>
              )}
            </tbody>
          </table>
          {get(data, "attendances", [])?.length > 0 ? (
            ""
          ) : loader ? (
            ""
          ) : (
            <p className="loader-text">Hozircha studentlar mavjud emas :(</p>
          )}
        </div>
        {loader ? <Loader /> : ""}
      </div>
    </>
  );
};

export default Table;
