import React from 'react';

const MinusIcon = (props: any) => {
    return (
        <svg {...props} version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill="#FC0707" stroke="none">
                <path d="M240 2856 c-95 -20 -172 -83 -212 -174 -79 -178 33 -387 225 -421 89
-15 4528 -16 4613 0 103 19 184 82 226 177 79 178 -33 387 -225 421 -94 16
-4551 14 -4627 -3z"/>
            </g>
        </svg>
    );
};

export default MinusIcon;