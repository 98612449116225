import React from "react";

const Chevron = () => {
  return (
    <>
      <svg
        width="15"
        height="8"
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5575 0.434937L5 3.87744L8.4425 0.434937L9.5 1.49994L5 5.99994L0.5 1.49994L1.5575 0.434937Z"
          fill="#313649"
        />
      </svg>
    </>
  );
};

export default Chevron;
