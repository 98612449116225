import React from "react";
import Information from "./Information";

const index = () => {
  return (
    <>
      <Information />
    </>
  );
};

export default index;
