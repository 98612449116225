import axios from "axios";
import { SearchParams } from "components/common/urlSetParams/urlSetParams";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import { IData } from "types/common";
import InformationContent from "../components/informationContent/InformationContent";
import InformationHeader from "../components/informationHeader/InformationHeader";

const Information = () => {
  const [setParams, getParams] = SearchParams();
  const [loader, setLoader] = useState<boolean>(false);
  const [headerData, setHeaderData] = useState<any>();
  const [data, setData] = useState<IData | null>(null);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_PRODUCTION_URL +
          `/group-attendance?${
            Object.keys(getParams())[0] + "=" + getParams().params
          }`
      )
      .then(function (response) {
        setData(get(response, "data.data", null));
        setHeaderData(get(response, "data.data", null));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <InformationHeader data={data} />
      <InformationContent loader={loader} data={data} />
    </>
  );
};

export default Information;
