export default function makeQueryString(params: any) {
    let queryString: string = "";

    Object.keys(params).forEach((key: any) => {
        if (
            params[key] !== null &&
            params[key] !== "" &&
            params[key] !== undefined
        ) {
            let value = params[key];
            if (typeof value === "string") {
                value = encodeURI(value);
            }
            if (Array.isArray(value)) {
                value = JSON.stringify(value);
            }
            queryString = `${queryString + key}=${value}&`
        }
    });
    queryString = queryString.slice(0, -1);
    queryString = queryString ? `?${queryString}` : queryString;

    return queryString;
}