import "./CustomSelect.style.css";
import { ICustomSelectT } from "./CustomSelect.type";
import Chevron from "./assets/Chevron";

const CustomSelect = ({ label, value }: ICustomSelectT) => {
  return (
    <div className="custom-input">
      {label && <div className="select-label">{label}</div>}
      <div className={`main`}>
        <div className={"drop-down-container"}>
          <div className={"drop-down-header"}>
            <div className="drop-down-content">
              <p className="dropdown-value">{value}</p>
              {/* <div className="close">&#10005;</div> */}
            </div>
            <div className="chevron">
              <Chevron />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
